iframe {
    width: 100%;
    height: 500px;  /* Ajusté de 315px à 500px */
}

@media (max-width: 480px) {
    .transparent-card iframe {
        width: 100%;
        height: 280px;  /* Ajusté de 210px à 280px */
    }
}

@media (min-width: 772px) {
    .transparent-card iframe {
        width: 100%;
        height: 400px;  /* Ajusté de 210px à 400px */
    }
}
