.logo-image{
  margin-right: 20px;
}

.Navbar_boubou{
  margin-top: 10px;
  margin-bottom: 10px;
}

.titre_header{
  font-size: 2em;
  font-family:  'ADLaM Display', sans-serif;
}
@media (max-width: 768px) {
  .titre_header{
    font-size: 1em;
    font-family:  'ADLaM Display', sans-serif;
  }
}
.titre_header2{
  margin-bottom: 20px;
  font-size: 3em;
  font-family:  'ADLaM Display', sans-serif;
}